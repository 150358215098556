import { ChakraProvider } from '@chakra-ui/react'
import HomePage from './container/HomgePage';
import useCheckMaintenance from 'hooks/useCheckMaintenance'
import Maintenance from './container/Maintenance';
import { Routes, Route } from "react-router-dom";
import withAuthContext from 'context'
import Navbar from 'component/Navbar';
import Footer from 'component/Footer';
import theme from "./theme";
import { WalletProvider, } from '@blocto/aptos-wallet-adapter';
import walletsList from 'utils/walletsList'
import { PAGE_ERROR_TYPE } from 'constants/index'

const wallets = Object.values(walletsList)

const App = () => {
  useCheckMaintenance()

  return <ChakraProvider theme={theme}>
    <WalletProvider
      wallets={wallets}
      autoConnect={true}
      onError={(error: Error) => {
        console.log('Adapter Error: ', error);
      }}>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/maintenance" element={<Maintenance />} />
        <Route path="*" element={<Maintenance error={PAGE_ERROR_TYPE.NotFound} />} />
      </Routes>
      <Footer />
    </WalletProvider>
  </ChakraProvider >;
}

export default withAuthContext(App)