import { useEffect } from 'react';
import { getBlockchainInfo } from 'apis'
import { PAGE_ERROR_TYPE } from 'constants/index'
import { useNavigate } from 'react-router-dom'

interface ChainInfo {
  chain_error?: string,
  is_under_maintenance?: boolean,
  name: string
}
export default function useCheckMaintenance() {

  const navigate = useNavigate()

  useEffect(() => {
    const check = async () => {
      try {
        const { blockchains }: { blockchains?: [ChainInfo] } = await getBlockchainInfo()
        const aptosInfo = blockchains?.find(({ name }) => name === 'aptos') ?? { chain_error: '', is_under_maintenance: false }

        if (!aptosInfo) {
          navigate(`/maintenance?error=${PAGE_ERROR_TYPE.ServiceUnderMaintenance}`)
        }

        const { chain_error, is_under_maintenance = false } = aptosInfo

        if (!!chain_error) {
          navigate(`/maintenance?error=${PAGE_ERROR_TYPE.ChainUnderMaintenance}`)
        } else if (is_under_maintenance) {
          navigate(`/maintenance?error=${PAGE_ERROR_TYPE.ServiceUnderMaintenance}`)
        }

      } catch (error) {
        navigate(`/maintenance?error=${PAGE_ERROR_TYPE.ServiceUnderMaintenance}`)
      }
    }
    check()
  }, [navigate])
}