export const HEADER_HEIGHT = 76;
export const PAGE_ERROR_TYPE = {
  NotFound: 'notfound',
  ChainUnderMaintenance: 'chain_under_maintenance',
  ServiceUnderMaintenance: 'service_under_maintenance'
}


export const NFT_MODULE_ADDRESS = {
  mainnet: '0x8998128cb52bf179ca9ba4fdb86651ab2b067c6f923e32dd73f3df542bf85638',
  testnet: '0xc772058ad2cba99bae4d453361189613f13a2ae76f0fa6c6fabde703ef38f699'
}