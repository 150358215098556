import { cloneElement, useEffect, ReactElement, useCallback, useContext } from 'react';
import {
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Image,
  Text,
  UnorderedList,
  ListItem
} from '@chakra-ui/react'
import bloctoLogo from 'assets/blocto.svg'
import AuthContext from "context/auth";
import walletsList from 'utils/walletsList';
import { useWallet } from '@blocto/aptos-wallet-adapter'


const WalletOption = ({
  logo,
  name,
  onClick
}: {
  logo: string,
  name: string,
  onClick?: React.MouseEventHandler
}) => {
  return <Flex
    borderRadius="12px"
    padding="16px 20px"
    alignItems="center"
    bg="background.tertiary"
    cursor='pointer'
    onClick={onClick}
    transition="all .2s"
    _hover={{ transform: "scale(0.98)" }}
    _active={{ transform: "scale(0.98)" }}
  >
    <Image src={logo}
      width="32px"
      height="32px"
      marginRight="16px"
      fontSize="14px" />
    <Text> {name}</Text>
  </Flex>
}

interface WalletOptions {
  [key: string]: ReactElement
}
const walletOptions: WalletOptions = {
  blocto: <WalletOption logo={bloctoLogo} name={'Blocto'} />
}

const AdapterModal = () => {
  const { isAdapterOpen, onAdapterClose, } = useContext(AuthContext);
  const { select, connect, wallet: currentWallet, autoConnect } = useWallet();

  useEffect(() => {
    if (!autoConnect && currentWallet?.adapter) {
      connect();
    }
  }, [autoConnect, currentWallet, connect]);


  const onOptionClick = useCallback((walletKey: string) => async () => {
    const selectedWallet = walletsList[walletKey]
    select(selectedWallet.name)
    onAdapterClose()
  }, [onAdapterClose, select])

  return <>
    <Modal isOpen={isAdapterOpen} onClose={onAdapterClose} >
      <ModalOverlay />
      <ModalContent borderRadius="20px" width={{ base: 'calc(100% - 40px)', md: "100%" }}>
        <ModalHeader fontWeight="normal" fontSize="16px" padding="16px 24px" borderBottom="1px solid #E1E4E8">Connect Wallet</ModalHeader>
        <ModalCloseButton top="16px" right="24px" />
        <ModalBody padding="24px">
          <UnorderedList margin="0px">
            {Object.entries(walletOptions).map(
              ([key, component]: [string, ReactElement]) => {
                return <ListItem listStyleType="none" key={key}>
                  {cloneElement(component, { onClick: onOptionClick(key) })}
                </ListItem>
              })
            }
          </UnorderedList>
        </ModalBody>
      </ModalContent>
    </Modal>
  </>
}
export default AdapterModal;