
import { extendTheme } from "@chakra-ui/react"
const breakpoints = {
  xs: '360px',
}

const theme = extendTheme({
  breakpoints,
  fonts: {
    heading: 'Work Sans, sans-serif',
    body: 'Work Sans, sans-serif',
  },
  colors: {
    black: '#141414',
    white: '#ffffff',
    gray01: '#7F7F7F',
    primary: {
      100: "#f0f8ff",
      300: "#e0f0ff",
      500: "#a6d9ff",
      700: "#0a94ff",
      900: "#0d36db",
    },
    background:{
      tertiary: '#F9F9F9'
    },
    secondary: {
      500: "#ff5555"
    }
  },
  components: {
    Text: {
      baseStyle: {
        color: '#141414',
      },
    },
    variants: {
      secondary: {
        color: '#F9F9F9',
      },
    },
  },
  styles:{
      global: {
        body: {
          color: '#141414',
        }
    },
  }
})

export default theme
