import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(isSameOrBefore)
dayjs.extend(utc)

export function isEventExpired(){ 
  const deadline = dayjs("2022-10-31").utc(8)
  const today = dayjs().utc(8)
  const result = dayjs(today).isSameOrBefore(deadline, 'day')
  
  return !result
}