import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Spinner
} from '@chakra-ui/react'

interface Prop {
  title?: string,
  isOpen: boolean,
  isLoading: boolean,
  onClose: () => void,
  content: React.ReactNode,
  confirmText: string,
}

function ConfirmModal({ isOpen, onClose, title, content, confirmText, isLoading }: Prop) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent
          width={{ base: 'calc(100% - 40px)', md: "100%" }}
          maxWidth="400px"
          borderRadius="20px">

          <ModalHeader
            py="24px"
            borderBottom="1px solid #E1E4E8">{title}</ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            {isLoading ? <>
              <Flex direction="column" alignItems="center" py="36px">
                <Spinner mb="20px" color="#14AAFF" width="65px" height="65px" thickness='5px' speed='0.65s' />
                <Text mb="20px">Waiting for confirmation</Text>
                <Text >It may take some time.</Text>
              </Flex>
            </> : content}
          </ModalBody>

          {!isLoading && <ModalFooter padding="26px 24px">

            <Button
              bg="#0075FF"
              colorScheme='blue'
              onClick={onClose}
              width="100%"
              py="16px"
              _hover={{ opacity: 0.8, transform: "scale(0.98)", bg: "primary.700" }}>
              {confirmText}
            </Button>

          </ModalFooter>
          }
        </ModalContent>
      </Modal>
    </>
  )
}

export default ConfirmModal