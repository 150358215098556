import {
  createContext,
  ElementType,
} from "react";
import { useDisclosure } from '@chakra-ui/react';

interface AuthContextInterface {
  isAdapterOpen: boolean,
  onAdapterOpen: () => void,
  onAdapterClose: () => void
}

const AuthContext = createContext<AuthContextInterface>({
  isAdapterOpen: false,
  onAdapterOpen: () => { },
  onAdapterClose: () => { }
});

export const withAuthContext = (Component: ElementType) => (props: any) => {
  const {
    isOpen: isAdapterOpen,
    onOpen: onAdapterOpen,
    onClose: onAdapterClose,
  } = useDisclosure()

  return (
    <AuthContext.Provider value={{
      isAdapterOpen,
      onAdapterOpen,
      onAdapterClose
    }}>
      <Component {...props} />
    </AuthContext.Provider>
  );
};

export default AuthContext;


