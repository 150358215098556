import axios from 'axios'

interface BloctoAPI {
  [key: string]: string,
}

const BLOCTO_API_URL: BloctoAPI = {
  mainnet: 'https://flow-wallet.blocto.app',
  testnet: 'https://flow-wallet-testnet.blocto.app'
}

const NETWORK = process.env.REACT_APP_NETWORK || 'testnet'
const isMainnet = process.env.REACT_APP_NETWORK === "mainnet"
const RPC_URL = isMainnet ? 'https://fullnode.mainnet.aptoslabs.com' : 'https://fullnode.testnet.aptoslabs.com'

const CONTRACT_MINT_HANDLE_MAINNET = '0xeeda0308418537473b720c11134cc6f746c3cd7bfd98094f1f2b37f22a508d71'
const CONTRACT_MINT_HANDLE_TESTNET = '0xc36a16bad42a29934115a9be34f1971c90884d003a68697b84fd2663504ffb9'
const MINT_HANDLE_ADDRESS = isMainnet ? CONTRACT_MINT_HANDLE_MAINNET : CONTRACT_MINT_HANDLE_TESTNET

const MINTER_TABLE_URL = `${RPC_URL}/v1/tables/${MINT_HANDLE_ADDRESS}/item`

export const fetchMinterCount = (address: string) => axios.post<string>(MINTER_TABLE_URL, {
  key_type: "address",
  value_type: "u64",
  key: address
}).then(({ data }) => data)


export const getTxOnExplorer = (txHash: string) => axios.get(
  `${RPC_URL}/v1/transactions/by_hash/${txHash}`,
)

export const getBlockchainInfo = () => axios.get(`${BLOCTO_API_URL[NETWORK]}/blocto/blockchainInfo`).then(({ data }) => data)