import { useQuery } from 'hooks/useQuery'
import Maintain from 'component/Maintain'
import { PAGE_ERROR_TYPE } from 'constants/index'

const Maintenance = ({ error }: { error?: string }) => {
  const query = useQuery()
  const switchDisplay = (error: any) => {
    switch (error) {
      case PAGE_ERROR_TYPE.NotFound:
        return {
          type: PAGE_ERROR_TYPE.NotFound,
          detail: 'Sorry...Page Not Found.'
        }
      case PAGE_ERROR_TYPE.ChainUnderMaintenance:
        return {
          type: PAGE_ERROR_TYPE.ChainUnderMaintenance,
          title: 'Aptos under maintenance.',
          detail: 'Aptos is currently undergoing maintenance. We will be back soon. Thank you for your patience.'
        }
      default:
      case PAGE_ERROR_TYPE.ServiceUnderMaintenance:
        return {
          type: PAGE_ERROR_TYPE.ServiceUnderMaintenance,
          title: 'We’re under maintenance.',
          detail: 'Our website is currently undergoing maintenance. We will be back soon. Thank you for your patience.'
        }
    }
  }
  let { type, detail, title } = switchDisplay(query.get('error') || error)
  return <Maintain type={type} title={title} detail={detail} />
}
export default Maintenance