import { useEffect, useState, useRef, useCallback, useContext } from 'react';
import { Box, Flex, Image, Heading, Text, Button, Link, useDisclosure } from "@chakra-ui/react";
import BloctoNFT from 'assets/blocto-aptos-nft.svg';
import { HEADER_HEIGHT } from 'constants/index';
import AdapterModal from 'component/AdapterModal';
import ConfirmModal from 'component/ConfirmModal';
import { useWallet } from '@blocto/aptos-wallet-adapter';
import CheckCircleIcon from 'assets/CheckCircle.svg'
import { useQuery } from 'hooks/useQuery'
import { fetchMinterCount, getTxOnExplorer } from 'apis'
import AuthContext from "context/auth";
import { AptosClient } from 'aptos'
import { isEventExpired } from 'utils'
import { NFT_MODULE_ADDRESS } from 'constants/index'

const aptosClient = new AptosClient(
  process.env.REACT_APP_NODE_URL || 'https://fullnode.testnet.aptoslabs.com'
);


const NETWORK_TYPE = process.env.REACT_APP_NETWORK === "mainnet" ? "mainnet" : "testnet"

const HomePage = () => {
  const [claiming, setClaiming] = useState(false)
  const [claimTxHash, setClaimTxHash] = useState('')
  const [txExist, setTxExist] = useState(false)
  const [mintedNumber, setMintedNumber] = useState('');
  const txTimer = useRef<number>(0)
  const query = useQuery()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { onAdapterOpen } = useContext(AuthContext);
  const { signAndSubmitTransaction, account } = useWallet();

  const address = account?.address?.toString() || null;

  useEffect(() => {
    if (!address) {
      setMintedNumber('')
      setClaimTxHash('')
      setTxExist(false)
      setClaiming(false)
      return
    }

    fetchMinterCount(address).then((number) => {
      setMintedNumber(number)
    }).catch((e) => {
      setMintedNumber('')
    })

    if (!txExist) return

    (async () => {
      try {
        await aptosClient.waitForTransaction(claimTxHash)
        fetchMinterCount(address).then((number) => {
          setMintedNumber(number)
        }).catch((e) => {
          setMintedNumber('')
        })
      } catch {
        setMintedNumber('')
      }
    })()

  }, [address, txExist, claimTxHash])

  useEffect(() => {
    if (txExist) {
      txTimer.current && clearInterval(txTimer.current)
    }

    return () => {
      txTimer.current && clearInterval(txTimer.current)
    }
  }, [txExist])

  const checkTxExist = async (txHash: string = '') => {
    try {
      const tx = await getTxOnExplorer(txHash)
      return !!tx
    } catch {
      return false
    }
  }

  const onClaim = useCallback(
    async () => {
      setClaiming(true)
      const contractAddr = NFT_MODULE_ADDRESS[NETWORK_TYPE] || NFT_MODULE_ADDRESS['testnet']
      const transaction = {
        arguments: query.get('referral') ? [query.get('referral')] : [""],
        function: `${contractAddr}::blocto_lfb::claim_mint`,
        type: 'entry_function_payload',
        type_arguments: [],
      };

      try {
        const result = await signAndSubmitTransaction(transaction)
        const txHash = result?.hash
        if (txHash) {
          onOpen()
          const check = () => checkTxExist(result?.hash).then((result) => {
            if (result && !txExist) {
              setClaimTxHash(txHash || '')
              setTxExist(true)
              setClaiming(false)
            }
          })
          const timer = setInterval(check, 1000)
          check()
          //@ts-ignore
          txTimer.current = timer
        }

      } catch {
        setClaiming(false)
      }

    }, [onOpen, query, txExist, signAndSubmitTransaction])

  const renderClaimButton = () => {
    if (!!mintedNumber || !!claimTxHash) return null

    if (address) {
      return !isEventExpired() && (
        <Button
          flex="1"
          bg="primary.700"
          maxWidth="350px"
          width="100%"
          borderRadius={100}
          marginBottom={{ base: '40px', xl: 0 }}
          _hover={{ opacity: 0.8, transform: "scale(0.98)", bg: "primary.700" }}
          onClick={onClaim}
          colorScheme="teal"
          color="white"
          isLoading={claiming}>
          <Text color="white">Claim your NFT with Blocto !</Text>
        </Button>
      )
    } else {
      return (
        <Button
          flex="1"
          bg="primary.700"
          maxWidth="350px"
          width="100%"
          borderRadius={100}
          display="inline-block"
          marginBottom={{ base: '40px', xl: 0 }}
          _hover={{ opacity: 0.8, transform: "scale(0.98)", bg: "primary.700" }}
          onClick={onAdapterOpen}>
          <Text color="white">
            Connect Wallet
          </Text>
        </Button>
      )
    }
  }
  return <>
    <Box
      display="inline-block"
      px={{ base: "20px", md: "165px" }}
      paddingTop="80px"
      paddingBottom={{ base: "272px", md: "160px" }}
      marginTop={`${HEADER_HEIGHT}px`}
      width="100%"
    >
      <Flex
        maxWidth={{ base: "540px", xl: "1110px" }}
        margin="0 auto"
        flexDirection={{ base: 'column', xl: 'row' }}>
        <Box
          flex="1"
          marginRight={{ base: 0, xl: '30px' }}
          textAlign="center">

          <Box
            flex="1"
            marginBottom="32px"
            textAlign="center"
            position='relative'
            paddingBottom="100%">

            <Image
              src={BloctoNFT}
              bg="background.tertiary"
              position='absolute'
              width="100%"
              overflow="hidden"
              borderRadius="12px"
              alt="Blocto x Aptos NFT"
            />

          </Box>

          {renderClaimButton()}

        </Box>
        <Box
          flex="1"
          maxWidth="540px">


          <Heading as='h1' fontWeight="bold" marginBottom="24px">
            {mintedNumber ? `LFB #${mintedNumber}` : `LFB #`}
          </Heading>

          <Box
            marginBottom="24px"
            bg="background.tertiary"
            width="100%"
            borderRadius={12}
            padding="20px 25px"
            display="inline-block"
          >
            <Heading as="h5" marginBottom="15px" size='sm' fontWeight={600}>
              Claimable Period
            </Heading>
            <Text
              bg="white"
              borderRadius={12}
              padding="16px 20px">
              {isEventExpired() ? "Ended!" : "Now - 2022/10/31 23:59 (UTC+8)"}
            </Text>
          </Box>

          <Box
            marginBottom="24px"
            bg="background.tertiary"
            width="100%"
            borderRadius={12}
            padding="20px 25px"
            display="inline-block"
          >
            <Heading as="h5" marginBottom="15px" size='sm' fontWeight={600}>
              Description
            </Heading>
            <Box
              width="100%"
              height="1px"
              border="1px solid #EFEFEF" />

            <Text padding="16px 0">
              {isEventExpired() ?
                "A limited-edition NFT from Blocto to celebrate Aptos's long-awaited mainnet launch. Claiming period ended. Thank you for your participation! Follow @BloctoApp on Twitter and join our discord to get access to more future campaigns!" :
                "A limited-edition NFT from Blocto to celebrate Aptos’s long-awaited mainnet launch. Excited to start!"}
            </Text>
          </Box>

        </Box>

      </Flex>
    </Box >

    <ConfirmModal
      isOpen={isOpen}
      onClose={onClose}
      confirmText={'Close'}
      isLoading={claiming}
      content={
        <Flex direction="column" alignItems="center" pt="18px">
          <Image src={CheckCircleIcon} alt="congrats" mb="16px" width="65px" height="65px" />
          <Text mb="16px">Congrats!</Text>
          <Link
            href={`https://explorer.aptoslabs.com/txn/${claimTxHash}?network=${NETWORK_TYPE}`}
            target="_blank"
            textDecoration='none'
            rel="noopener noreferrer"
            color="#0075FF">
            View on explorer
          </Link>
        </Flex>
      }
    />
    <AdapterModal />
  </>;
}
export default HomePage;