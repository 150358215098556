import {
  BloctoWalletAdapter,
  WalletAdapterNetwork,
  BaseWalletAdapter
} from '@blocto/aptos-wallet-adapter';
interface Adapter {
  [key: string]: BaseWalletAdapter
}
const isMainnet = process.env.REACT_APP_NETWORK === "mainnet"

const network = isMainnet ?
  WalletAdapterNetwork.Mainnet :
  WalletAdapterNetwork.Testnet

const walletsList: Adapter = {
  blocto: new BloctoWalletAdapter({ network }),
}

export default walletsList;