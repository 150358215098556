import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { Flex, Text, Img } from "@chakra-ui/react";
import WalletConnect from 'component/WalletConnect';
import bloctoLogo from 'assets/blocto.svg';
import { HEADER_HEIGHT } from 'constants/index'

const Navbar = () => {
  const location = useLocation()
  const isAtMaintenancePage = location.pathname.includes('maintenance')
  return (
    <Flex
      justify="space-between"
      align="center"
      height={HEADER_HEIGHT}
      px="30px"
      pos="fixed"
      bg="transparent"
      top={0}
      left={0}
      right={0}
      zIndex={10}
      borderBottom="1px solid #F9F9F9"
      _before={{
        pos: "absolute",
        width: "100%",
        height: "100%",
        content: '""',
        left: 0,
        bg: "rgba(255, 255, 255, 0.9)",
        backdropFilter: "blur(20px)",
        zIndex: -1,
      }}
    >
      <Link to="/">
        <Flex
          align="center"
          transition="all .2s"
          _hover={{
            opacity: 0.8,
            transform: "scale(0.98)",
          }}
        >
          <Img src={bloctoLogo} width={{ base: "30px", lg: "40px" }} />
          <Text fontSize="2xl" fontWeight="bold" mx={2}>
            BLOCTO
          </Text>
        </Flex>
      </Link>

      {!isAtMaintenancePage && <WalletConnect />}
    </Flex>
  )
};
Navbar.HEIGHT = 76;

export default Navbar;
