import { ChakraProps, Icon } from "@chakra-ui/react";

const Instagram = (props: ChakraProps) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      d="M12,3c2.4,0,2.8,0,3.7,0.1c1,0,1.6,0.2,2.2,0.4c0.6,0.2,1.1,0.5,1.6,1c0.5,0.4,0.8,1,1,1.6
	c0.2,0.6,0.4,1.2,0.4,2.2c0,1,0.1,1.3,0.1,3.7s0,2.8-0.1,3.7c0,1-0.2,1.6-0.4,2.2c-0.2,0.6-0.6,1.1-1,1.6c-0.4,0.5-1,0.8-1.6,1
	c-0.6,0.2-1.2,0.4-2.2,0.4c-1,0-1.3,0.1-3.7,0.1s-2.8,0-3.7-0.1c-1,0-1.6-0.2-2.2-0.4c-0.6-0.2-1.1-0.6-1.6-1c-0.5-0.4-0.8-1-1-1.6
	c-0.2-0.6-0.4-1.2-0.4-2.2C3,14.8,3,14.4,3,12s0-2.8,0.1-3.7c0-1,0.2-1.6,0.4-2.2c0.2-0.6,0.6-1.1,1-1.6c0.4-0.5,1-0.8,1.6-1
	c0.6-0.2,1.2-0.4,2.2-0.4C9.2,3,9.6,3,12,3z M12,7.5c-1.2,0-2.3,0.5-3.2,1.3s-1.3,2-1.3,3.2s0.5,2.3,1.3,3.2c0.8,0.8,2,1.3,3.2,1.3
	s2.3-0.5,3.2-1.3c0.8-0.8,1.3-2,1.3-3.2S16,9.7,15.2,8.8S13.2,7.5,12,7.5z M17.9,7.3c0-0.3-0.1-0.6-0.3-0.8
	c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8s0.1,0.6,0.3,0.8c0.2,0.2,0.5,0.3,0.8,0.3
	c0.3,0,0.6-0.1,0.8-0.3C17.7,7.9,17.9,7.6,17.9,7.3z M12,9.3c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
	c-0.5,0.5-1.2,0.8-1.9,0.8s-1.4-0.3-1.9-0.8c-0.5-0.5-0.8-1.2-0.8-1.9s0.3-1.4,0.8-1.9C10.6,9.6,11.3,9.3,12,9.3z"
    />
  </Icon>
);

export default Instagram;
