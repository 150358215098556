import { Box, Flex, Center, Text, Image } from '@chakra-ui/react'
import MaintainIcon from 'assets/blocto_maintenance.svg'
import NotfoundIcon from 'assets/404.svg'
import AptosMaintainIcon from 'assets/aptos_maintenance.svg'
import { HEADER_HEIGHT } from 'constants/index';
import { PAGE_ERROR_TYPE } from 'constants/index'

const DisplayImage = ({ type }: { type: string | undefined }) => {
  switch (type) {
    case PAGE_ERROR_TYPE.ChainUnderMaintenance:
      return <Image src={AptosMaintainIcon} width={{ base: '100%', md: '350px' }} maxWidth="350px" />
    case PAGE_ERROR_TYPE.NotFound:
      return <Image src={NotfoundIcon} width={{ base: '100%', md: '350px' }} maxWidth="350px" />
    default:
      return <Image src={MaintainIcon} width={{ base: '100%', md: '350px' }} maxWidth="350px" />
  }
}

const Maintenance = ({
  type,
  title,
  detail,
}: {
  type?: string
  title?: string
  detail?: string
}) => {
  return (
    <Box mt={HEADER_HEIGHT}>
      <Center h={`calc(100vh - ${HEADER_HEIGHT}px)`} backgroundColor="#F9F9F9" px="20px" >
        <Flex
          color="#fff"
          flexDir="column"
          justify="center"
          alignItems="center"
          maxWidth="500px"
        >
          <DisplayImage type={type} />
          <Text fontSize="28px" textAlign="center" mb="15px" mt="15px">
            {title}
          </Text>
          <Text textAlign="center">{detail}</Text>
        </Flex>
      </Center>
    </Box >
  )
}
export default Maintenance